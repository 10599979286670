<template>
  <eagle-list
    :list-key="listKey"
  ></eagle-list>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './providerListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'provider-list',
    meta: {},
    redirectUrl: '/linerp/provider/info',
  }),
  methods: {
    async beforeIndex() {
      await Promise.all([this.getProductCategory()])
    },
    async getProductCategory() {
      const res = await this.$api.collection.productCategoryApi.tree()
      this.meta.firstCategories = res? res.children : []
    },
    async indexApi(params) {
      return await this.$api.collection.providerApi.index(params)
    },
    async patchApi(value, row, config) {
      const target = row.id
      const column = config.key
      return await this.$api.collection.providerApi.patch(target, column, value)
    },
    async deleteApi(target) {
      return await this.$api.collection.providerApi.delete(target)
    },
    async batchApi(targets, column, value) {
      return await this.$api.collection.providerApi.batch(targets, column, value)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.providerApi.batchDelete(targets)
    },
    getListConfig() {
      return listConfig
    },
    async loginViaOfficialMember(provider) {
      let result = null
      try {
        result = await this.$api.collection.memberApi.getOfficialMemberToken(provider.id)
        const loginUrl = `${this.frontendWebDomain}/linerp/login-via-token?token=${result.token}&provider=${provider.id}&path=${this.redirectUrl}`
        window.open(loginUrl)
      } catch (error) {
        console.warn(error)
      }
    },
  },
  computed: {
    frontendWebDomain() {
      return this.$store.getters['base/frontendWebDomain']
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
