import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.provider',
      search: 'provider.search_keyword2',
      filter: {
        product_category: {
          label: 'provider.data.product_category',
          type: 'selection',
          options: (listMeta) => {
            if(!listMeta) return []
            if(!Array.isArray(listMeta.firstCategories)) return []
            return listMeta.firstCategories.map((category: any) => ({text: category.name, value: category.id}))
          }
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        title: row.title,
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      card: {
        title: row => row.name,
        subtitle: row => row.name,
        avatarPhoto: row => row.avatar,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'name',
            label: 'provider.data.name',
            type: 'text',
          },
          {
            key: 'member_name',
            label: 'data.manager-name',
            type: 'text',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.name,
        },
      },
      metaAction: {},
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'provider-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          removeTarget: row => row.name,
        },
        loginViaOfficialMember: {
          label: '登入前台',
          // @ts-ignore
          callback: row => this.vm.loginViaOfficialMember(row),
        },
        bindAdmin: {
          component: () => import('modules/member/components/bindAdmin/bindAdminBtn.vue'),
        },
      },
    }

  }
}

export default new listConfig()
